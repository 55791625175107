import React, { useState } from 'react';
import ModeButton from '../ModeButton';

const Login = ({ onLoginSuccess, toggleTheme, mode, setMode }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await fetch('https://dml-portal-functions.azurewebsites.net/api/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Login failed:', response.status, errorText);
        throw new Error(`Login failed: ${response.status} ${errorText}`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Received non-JSON response from server');
      }

      const data = await response.json();

      if (data && data.success) {
        localStorage.setItem('user', JSON.stringify({
          reports: data.reports,
          role: data.role,
          username: username
        }));
        onLoginSuccess(data.reports, data.role);
      } else {
        throw new Error('Login was not successful');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError(`Login failed: ${error.message}`);
    }
  };

  return (
    <>
      {/* <div className='bg-white dark:bg-[#1e1e2f]  '>
      </div> */}
      <section className={`${mode === 'dark' ? 'bg-[#1e1e2f] text-white' : 'bg-[#f9fafb] text-[#212121]'}  min-h-[95vh]`}>
        <div className='flex justify-end px-6 pt-6 w-screen absolute z-50'>
          <ModeButton toggleTheme={toggleTheme} mode={mode} />
        </div>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0 relative">
          <div className="flex flex-col items-center mb-6 text-2xl font-semibold">
            <img src='/virtuallawyer.png' alt="icon" className="w-24 h-24 mr-2 text-accent" />
            <p className='mt-2'>Virtual Lawyer</p>
          </div>
          <div className={`${mode === 'dark' ? 'bg-[#1e1e2f] text-white' : 'bg-[#f9fafb] text-[#212121]'}w-full rounded-lg shadow border border-gray-300 md:mt-0 sm:max-w-md xl:p-0 sm:min-w-[400px]`}>
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
                <div>
                  <label htmlFor="username" className="block mb-2 text-sm font-medium">Username</label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className={`${mode === 'dark' ? 'border-gray-600 bg-gray-700' : 'bg-[#f3f4f6] border-gray-300'} border sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                    placeholder="Your username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className={`${mode === 'dark' ? 'border-gray-600 bg-gray-700' : 'bg-[#f3f4f6] border-gray-300'} border sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {error && (
                  <div className="text-red-500 text-sm mt-2">
                    {error}
                  </div>
                )}
                <button
                  type="submit"
                  className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
import React, { useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { Scales } from "phosphor-react";
import ReactMarkdown from 'react-markdown';

function ChatMessage({ message }) {
    const [language, setLanguage] = useState('english');
    const isUser = message.role === 'user';

    const getCurrentContent = () => {
        if (isUser) return message.content;
        if (!message.content?.english) return message.content;
        return message.content[language];
    };

    const formatCitations = () => {
        if (!message.content?.citations) return null;
        
        return (
            <div className="mt-4 text-sm text-gray-600 dark:text-gray-300 border-t border-gray-200 dark:border-gray-600 pt-2">
                <p className="font-semibold mb-2">Citations:</p>
                {message.content.citations.map(citation => (
                    <div key={citation.number} className="mb-2">
                        <p className="font-medium">
                            [{citation.number}] - {citation.document}, pages {citation.pages}
                        </p>
                        <p className="italic text-gray-500 dark:text-gray-400 ml-4">
                            "{citation.quote}"
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    const LanguageTabs = () => {
        if (isUser || !message.content?.english) return null;
        
        return (
            <div className="flex mb-2 border-b border-gray-200 dark:border-gray-600">
                <button
                    className={`px-4 py-2 text-sm font-medium rounded-t-lg ${
                        language === 'english'
                            ? 'bg-gray-100 dark:bg-gray-700 text-[#60495A] dark:text-white border-b-2 border-[#60495A]'
                            : 'text-gray-500 hover:text-gray-600 dark:text-gray-400'
                    }`}
                    onClick={() => setLanguage('english')}
                >
                    English
                </button>
                <button
                    className={`px-4 py-2 text-sm font-medium rounded-t-lg ${
                        language === 'arabic'
                            ? 'bg-gray-100 dark:bg-gray-700 text-[#60495A] dark:text-white border-b-2 border-[#60495A]'
                            : 'text-gray-500 hover:text-gray-600 dark:text-gray-400'
                    }`}
                    onClick={() => setLanguage('arabic')}
                >
                    Arabic
                </button>
            </div>
        );
    };

    return (
        <div className={`flex items-start gap-2.5 mb-4 ${isUser ? 'justify-end' : 'justify-start'}`}>
            {!isUser && (
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#60495A] flex items-center justify-center">
                    <Scales className="text-white text-xl" weight="fill" />
                </div>
            )}
            <div className={`flex flex-col max-w-[80%] leading-1.5 p-4 border-gray-200 ${
                isUser 
                    ? 'bg-[#60495A] text-white rounded-s-xl rounded-ee-xl' 
                    : 'bg-gray-100 text-gray-900 dark:bg-[#2A2A40] dark:text-gray-100 rounded-e-xl rounded-es-xl'
            }`}>
                <div className="flex items-center space-x-2 rtl:space-x-reverse mb-2">
                    <span className="text-base font-semibold">
                        {isUser ? 'You' : 'Virtual Lawyer'}
                    </span>
                </div>
                <LanguageTabs />
                <div className={`text-base font-normal py-2.5 whitespace-pre-wrap break-words markdown-content ${
                    language === 'arabic' ? 'text-right' : ''
                }`}>
                    {isUser ? (
                        <p>{message.content}</p>
                    ) : (
                        <ReactMarkdown>{getCurrentContent()}</ReactMarkdown>
                    )}
                </div>
                {!isUser && formatCitations()}
            </div>
            {isUser && (
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#60495A] flex items-center justify-center">
                    <FaUser className="text-white text-lg" />
                </div>
            )}
        </div>
    );
}

export default ChatMessage;

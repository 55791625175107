import React, { useState, useRef } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';

function FileUploadComponent({ onChatIdChange = () => {}, mode }) {
  const [ingesting, setIngesting] = useState(false);
  const [ingestedFiles, setIngestedFiles] = useState([]);
  const fileUploadRef = useRef(null);
  // const [chatId, setChatId] = useState(null);
  const [isIndexing, setIsIndexing] = useState(false);

  const handleIngest = async () => {
    setIngesting(true);
    const formData = new FormData();
    const filesToIngest = fileUploadRef.current.getFiles();

    console.log(filesToIngest)
    
    filesToIngest.forEach(file => {
      formData.append('files', file);
      formData.append('fileTypes', file.type);
    });

    console.log("FormData entries:");
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1])
    }
  
    try {
      const response = await axios.post('https://dml-portal-functions.azurewebsites.net/api/ingest', formData);
      // const response = await axios.post('http://localhost:7071/api/ingest', formData);
      console.log('Ingest response:', response.data);
      
      setIngesting(false);
      fileUploadRef.current.clear();
      
      const newChatId = response.data.chatId;
      console.log('New chat ID received:', newChatId);
      
      // setChatId(newChatId);
      setIsIndexing(true);
      
      setTimeout(() => {
        setIngestedFiles(prevFiles => [...prevFiles, ...filesToIngest.map(file => file.name)]);
        setIsIndexing(false);
        console.log('Calling onChatIdChange with:', newChatId);
        onChatIdChange(newChatId);
        console.log('Called onChatIdChange successfully');
      }, 10000);
    } catch (error) {
      console.error('Error ingesting documents:', error);
      setIngesting(false);
    }
  };

  return (
    <div className="p-6 h-full flex flex-col bg-white dark:bg-[#1e1e2f] rounded-lg shadow-md border dark:border-gray-600">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">Upload Documents</h2>
      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_upload">
          Select multiple files to upload
        </label>
        <FileUpload 
          ref={fileUploadRef}
          id="file_upload"
          mode="advanced" 
          multiple 
          accept=".pdf,.doc,.docx,.txt" 
          maxFileSize={10485760} 
          customUpload={true}
          uploadHandler={handleIngest}
          auto={false}
          chooseLabel="Browse"
          uploadLabel="Ingest"
          cancelLabel="Clear"
          className={`w-full custom-file-upload ${mode === 'dark' ? 'bg-[#1e1e2f]': 'bg-white'}`} 
          chooseOptions={{
            className: 'px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 transition-colors'
          }}
          uploadOptions={{
            className: 'px-4 py-2 bg-[#4a90e2] text-white rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-400 transition-colors ml-2'
          }}
          cancelOptions={{
            className: 'px-4 py-2 bg-blue-100 text-blue-600 rounded-lg hover:bg-blue-200 focus:ring-4 focus:ring-blue-300 transition-colors ml-2'
          }}
        />
      </div>
      {(ingesting || isIndexing) && (
        <div className="flex flex-col items-center justify-center my-4">
          <ProgressSpinner 
            style={{width: '50px', height: '50px'}} 
            strokeWidth="4" 
            fill="var(--surface-ground)" 
            animationDuration=".5s" 
          />
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {ingesting ? "Ingesting files..." : "Syncing files with database..."}
          </p>
        </div>
      )}
      <div className="flex-1 overflow-y-auto">
        {ingestedFiles.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-3 text-gray-700 dark:text-gray-300">Ingested Files:</h3>
            <div className="space-y-2">
              {ingestedFiles.map((fileName, index) => (
                <div key={index} className="bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 text-blue-700 dark:text-blue-200 p-4 rounded-lg shadow">
                  <p className="font-medium">{fileName}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUploadComponent;

import React from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';

const ModeButton = ({ toggleTheme, mode }) => {
  return (
    <button
      onClick={toggleTheme}
      className={`p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors`}
      aria-label={`Switch to ${mode === 'dark' ? 'light' : 'dark'} mode`}
    >
      {mode === 'dark' ? (
        <FaSun className="w-5 h-5 text-yellow-300" />
      ) : (
        <FaMoon className="w-5 h-5 text-gray-800" />
      )}
    </button>
  );
};

export default ModeButton;

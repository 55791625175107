import React from 'react';
import ModeButton from './ModeButton';

const Sidebar = ({ toggleTheme, mode, setMode, onLogout }) => {
  return (
    <aside className={`${mode === 'light' ? 'bg-gray-100 text-[#212121]' : 'bg-[#12121c] text-white'}`}>
      <div className="flex items-center justify-between px-6 pt-4 pb-1 bg-[#f9fafb] rounded-lg shadow-lg dark:bg-[#12121c]">
        <h2 className="text-xl font-semibold text-[#1E1E2F] dark:text-white">
          Virtual Lawyer
        </h2>
        <div className="flex items-center gap-3">
          {/* Theme Toggle Button */}
          <ModeButton toggleTheme={toggleTheme} mode={mode} setMode={setMode} />
          {/* Logout Button */}
          <button
            onClick={onLogout}
            className={`${mode === 'light' ? 'text-white bg-[#1E1E2F] hover:bg-[#2A2A40]' : 'text-white bg-[#242440] hover:bg-[#1E1E2F]'
              } focus:ring-4 focus:ring-[#4A90E2] font-medium rounded-lg text-sm px-4 py-2 text-center transition-colors duration-200 border border-[#2A2A3B]`}
          >
            <div className="flex items-center">
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              Logout
            </div>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;

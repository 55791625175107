import React, { useState, useEffect, useCallback } from 'react';
import Login from './components/auth/Login';
import Sidebar from './components/Sidebar';
// import UserManagement from './components/UserManagement';
// import DashboardManagement from './components/DashboardManagement';
import Layout from './components/Layout';
// import ReportContainer from './components/ReportContainer';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  // eslint-disable-next-line
  const [userReports, setUserReports] = useState([]);
  // eslint-disable-next-line
  const [selectedPage, setSelectedPage] = useState(null);
  // eslint-disable-next-line
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  // const [theme, setTheme] = useState(localStorage.getItem('darkmode') === 'true' ? 'dark' : 'light');
  const [mode, setMode] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });


  const fetchDashboards = useCallback(async () => {
    try {
      const response = await fetch('https://dml-portal-functions.azurewebsites.net/api/dashboards?visibleOnly=true', {
        method: 'GET',
        headers: {
          'X-User-Role': userRole
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setDashboards(data);
    } catch (error) {
      console.error("Error fetching dashboards:", error);
    }
  }, [userRole]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setIsLoggedIn(true);
      setUserReports(storedUser.reports);
      setUserRole(storedUser.role);
      fetchDashboards();
    }
  }, [fetchDashboards]);

  const handleLoginSuccess = (reports, role) => {
    setIsLoggedIn(true);
    setUserReports(reports);
    setUserRole(role);
    fetchDashboards();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserReports([]);
    setUserRole('');
    setSelectedPage(null);
    setDashboards([]);
    localStorage.removeItem('user');
  };

  const handleNavigate = (page, item) => {
    if (page === 'dashboards' && item) {

      setSelectedReportId(item.id);
      setSelectedPage('report');
    } else {
      setSelectedPage(item?.id || page);
      setSelectedReportId(null);
    }
  };

  // const toggleTheme = () => {
  //   setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  // };

  // useEffect(() => {
  //   document.body.classList.toggle('dark-mode', theme === 'dark');
  //   localStorage.setItem('darkmode', theme === 'dark' ? 'true' : 'false');
  // }, [theme]);


  // const handleDashboardUpdate = () => {
  //   fetchDashboards();
  // };

  // const renderContent = () => {
  //   console.log('App: Rendering content for selectedPage:', selectedPage);
  //   switch (selectedPage) {
  //     // case 'userManagement':
  //     //   return userRole === 'Admin' ? <UserManagement /> : <div>Access Denied</div>;
  //     // case 'dashboardManagement':
  //     //   return userRole === 'Admin' ? <DashboardManagement handleDashboardUpdate={handleDashboardUpdate} /> : <div>Access Denied</div>;
  //     // case 'documentQA':
  //     //   return <Layout />;
  //     // case 'bidBuilder':
  //     // case 'documentTranslator':
  //     //   return <div>This feature is coming soon!</div>;
  //     // case 'report':
  //     //   return <ReportContainer reportName={selectedReportId} userReports={userReports} />;
  //     // case 'dashboards':
  //     //   console.log('App: Rendering Dashboards SummaryPage');
  //     //   return <SummaryPage 
  //     //     title="Dashboards" 
  //     //     description="Browse Power BI dashboards. If you need access to a dashboard, please contact the Data & Machine Learning Team."
  //     //     items={dashboards}
  //     //     onNavigate={handleNavigate}
  //     //   />;
  //     // case 'spreadsheets':
  //     //   return <SummaryPage 
  //     //     title="Spreadsheets" 
  //     //     description="Access various spreadsheets for data analysis and reporting."
  //     //     items={[
  //     //       { name: "Active Users", id: "activeUsers" },
  //     //       { name: "VC In Cell Usage", id: "vcInCellUsage" }
  //     //     ]}
  //     //     onNavigate={handleNavigate}
  //     //   />;
  //     // case 'tools':
  //     //   return <SummaryPage 
  //     //     title="Tools" 
  //     //     description="Access various tools to assist with your work."
  //     //     items={[
  //     //       { name: "Document Chat", id: "documentQA" },
  //     //       { name: "Bid Builder", id: "bidBuilder" },
  //     //       { name: "Document Translator", id: "documentTranslator" }
  //     //     ]}
  //     //     onNavigate={handleNavigate}
  //       // />;
  //     // case 'admin portal':
  //     //   return userRole === 'Admin' ? <SummaryPage 
  //     //     title="Admin Portal" 
  //     //     description="Manage users and dashboards."
  //     //     items={[
  //     //       { name: "User Management", id: "userManagement" },
  //     //       { name: "Dashboard Management", id: "dashboardManagement" }
  //     //     ]}
  //     //     onNavigate={handleNavigate}
  //     //   /> : <div>Access Denied</div>;
  //     default:
  //       return <Layout/>;
  //   }
  // };

  const toggleTheme = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('theme', newMode);
  };

  useEffect(() => {
    document.body.className = mode;
  }, [mode]);

  const renderContent = () => {
    console.log('App: Rendering Layout');
    return <Layout mode={mode} toggleTheme={toggleTheme} />;
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <div className='h-screen'>
          <Sidebar
            mode={mode}
            setMode={setMode}
            toggleTheme={toggleTheme}
            onLogout={handleLogout}
            onNavigate={handleNavigate}
            userRole={userRole}
            dashboards={dashboards}
          />
          <main className="main-content">
            {renderContent()}
          </main>
        </div>
      ) : (
        <>
          <Login onLoginSuccess={handleLoginSuccess} toggleTheme={toggleTheme} mode={mode} setMode={setMode} />
        </>
      )}
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import FileUploadComponent from './FileUploadComponent';
import ChatInterface from './ChatInterface';

function Layout({ mode }) {
  const [chatId, setChatId] = useState(null);
  const [isIndexing, setIsIndexing] = useState(false);

  useEffect(() => {
    console.log('Layout chatId updated:', chatId);
  }, [chatId]);

  const handleChatIdChange = (newChatId) => {
    console.log('Layout handling new chat ID:', newChatId);
    setChatId(newChatId);
  };

  const handleIndexingChange = (indexing) => {
    setIsIndexing(indexing);
  };

  return (
    <div className={`${mode === 'light' ? 'bg-[#f9fafb] text-[#212121]' : 'bg-[#12121c] text-white'} flex h-[93vh] p-6`}>
      <div className="w-[70%] pr-6">
        <div className="bg-white rounded-lg shadow-lg h-full border dark:border-gray-600">
          <ChatInterface chatId={chatId} isIndexing={isIndexing} />
        </div>
      </div>
      <div className="w-[30%]">
        <FileUploadComponent
          mode={mode}
          onChatIdChange={handleChatIdChange}
          onIndexingChange={handleIndexingChange}
        />
      </div>
    </div>
  );
}

export default Layout;
